import * as React from "react"
import { graphql } from "gatsby"
import {
  Container,
  Section,
  FlexList,
  Text,
  Kicker,
  Heading,
  Box,
  KeyPointLink
} from "./ui"
import * as styles from "./keyPoints.css"

function KeyPoint(props) {
  return (
    <Box center>
      <div itemScope itemType="https://schema.org/VideoGame">
      <KeyPointLink links={props}/>
      <div itemProp="quest">
      <Text>{props.text}</Text>
      </div>
      </div>
    </Box>
  )
}

export default function KeyPoints(props) {
  return (
    <Section id="key">
      <Container>
        <Box center paddingY={4}>
          <Heading>
            {props.kicker && <Kicker>{props.kicker}</Kicker>}
            {props.heading}
          </Heading>
          {props.text && <Text>{props.text}</Text>}
        </Box>
        <FlexList className={styles.keyPointFlex} gap={4} variant="responsive">
          {props.content.map((keyPoint) => (
            <li className={styles.keyPointFlexItem} key={keyPoint.id}>
              <KeyPoint {...keyPoint} />
            </li>
          ))}
        </FlexList>
      </Container>
    </Section>
  )
}

export const query = graphql`
  fragment KeyPointsContent on KeyPoints {
    id
    kicker
    heading
    text
    content {
      id
      heading
      text
      image {
        alt
        id
        gatsbyImageData
      }
      links {
        id
        href
        text
      }
    }
  }
`
