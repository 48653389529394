import * as React from "react"
import { Container, Section } from "./ui"
import * as styles from "./youtube.css"

export default function Youtube() {
    return (
        <Section className={styles.slanted}>
            <div itemScope itemType="https://schema.org/VideoGame">
                <Container className={styles.youtubeContainer}>
                    <iframe 
                            width="100%" 
                            height="350px" 
                            src="https://www.youtube.com/embed/hvNdJQ1TM0s" 
                            title="YouTube video player" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" 
                            allowfullscreen="allowfullscreen"
                            mozallowfullscreen="mozallowfullscreen" 
                            msallowfullscreen="msallowfullscreen" 
                            oallowfullscreen="oallowfullscreen" 
                            webkitallowfullscreen="webkitallowfullscreen"
                            itemProp="trailer video">
                    </iframe>
                </Container>
            </div>
        </Section>
    )
}