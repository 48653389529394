import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"
import Fallback from "../components/fallback"
import { Seo } from "../components/seo"

export default function Homepage(props) {
  const { homepage } = props.data

  return (
    <main>
      <Layout {...homepage}>
        {homepage.blocks.map((block) => {
          const { id, blocktype, ...componentProps } = block
          const Component = sections[blocktype] || Fallback
          return <Component key={id} {...componentProps} />
        })}
      </Layout>
    </main>
  )
}

export const query = graphql`
  {
    homepage {
      id
      title
      description
      image {
        id
        url
      }
      blocks: content {
        id
        blocktype
        ...SpaceBannerContent
        ...HeroContent
        ...FeaturesContent
        ...CallToActionContent
        ...TestimonialsContent
        ...StatsContent
        ...KeyPointsContent
        ...GalleryContent
      }
    }
  }
`

export const Head = () => (
  <Seo/>
)
