import * as React from "react"
import { graphql } from "gatsby"
import {
  Container,
  Section,
} from "./ui"
import Youtube from "./youtube"
import * as styles from "./cta.css"

export default function CallToAction(props) {
  return (
    <Section className={styles.slanted}>
    <Container width="fullbleed">
      <Youtube/>
    </Container>
    </Section>
  )
}

export const query = graphql`
  fragment CallToActionContent on CallToAction {
    id
    kicker
    heading
    text
    image {
      alt
      id
      gatsbyImageData
    }
    links {
      id
      href
      text
    }
  }
`
