import * as React from "react"
import { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import * as styles from "./gallery.css"
import { Container, Section } from "./ui"
import FsLightbox from "fslightbox-react";

export default function Gallery(props) {

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  })

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    })
  }
  
  return (
    <>
      <Section className={styles.gallerySection}>
        <div itemscope itemType="https://schema.org/VideoGame">
          <Container width="fullbleed" className={styles.galleryContainer}>
            <div className={styles.galleryWrapper}>
              {props.images.map((image, i) => {
                return <div 
                className={styles.galleryImage}
                key={i}
                onClick={() => openLightboxOnSlide(i + 1)}
                itemProp="screenshot thumbnail">
                  <GatsbyImage
                  alt="Gallery screenshot of End's Reach gameplay"
                  image={getImage(image)}
                  />
                </div>
              })}
            </div>
          </Container>
        </div>
      </Section>
      <FsLightbox
      toggler={lightboxController.toggler}
      sources={props.images.map((image, i) => (
        <div className="galleryLightBox"
        key={i} 
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        itemProp="screenshot"
        >
          <GatsbyImage
          alt="Gallery screenshot of End's Reach gameplay"
          image={getImage(image)}
          style={{ width: "100%" }}
          />
        </div>
      ))}
      slide={lightboxController.slide}
      />
    </>
  )
}



export const query = graphql`
  fragment GalleryContent on Gallery {
    images {
      gatsbyImageData
    }
  }
`