import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  Container,
  Section,
  Flex,
  Box,
  Subhead,
  Kicker,
  Text,
  ButtonList,
} from "./ui"

export default function Feature(props) {
  return (
    <Section padding={4}>
        <Container>
          <Flex gap={4} variant="responsive">
            <Box width="half" order={props.flip ? 1 : null}>
              <div itemProp="screenshot thumbnail">
              {props.image && (
                <GatsbyImage
                  alt="Screenshot of End's Reach gameplay"
                  image={getImage(props.image.gatsbyImageData)}
                />
              )}
              </div>
            </Box>
            <Box width="half">
              <div itemscope itemType="https://schema.org/VideoGame">
                <Subhead>
                    {props.kicker && <Kicker>{props.kicker}</Kicker>}
                    <div itemProp="featureList">
                      {props.heading}
                    </div>
                </Subhead>
                  <Text itemProp="quest" variant="lead">{props.text}</Text>
                <ButtonList links={props.links} />
              </div>
            </Box>
          </Flex>
        </Container>
    </Section>
  )
}

export const query = graphql`
  fragment FeatureContent on Feature {
    id
    kicker
    heading
    text
    links {
      id
      href
      text
    }
    image {
      id
      gatsbyImageData
      alt
    }
  }
`
