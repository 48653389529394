import * as React from "react"
import { graphql } from "gatsby"
import ThreeLazy from './three-lazy';
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Header from "./header"
import Arrows from "./arrows";
import * as styles from "./space-banner.css"
import {
  Container,
  Heading,
  Section,
  Text,
} from "./ui"

export default function SpaceBanner(props) {
  return (
    <div className="relative overflow-hidden">
      <div className="min-h-full h-screen">
        <div className="relative max-w-section mx-auto flex items-center justify-center text-center w-full h-full z-10 p-4">
          <div itemScope itemType="https://schema.org/VideoGame">
            <Section>
                <Container className={styles.titleWrapper}>
                  <Header />
                  <div itemProp="name">
                    <Heading className={styles.splashTitle}>{props.heading}</Heading>
                  </div>
                  <div itemProp="creator musicBy">
                    <Text className={styles.splashSub}>{props.text}</Text>
                  </div>                
                </Container>
              <Container className={styles.arrowWrapper}>
                <a href="#key" aria-label="Scroll to key section">
                  <Arrows/>
                </a>
              </Container>
            </Section>
          </div>
        </div>
        <div className="bg-brand-background absolute top-0 left-0 w-full h-full z-0">
          <ThreeLazy />
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
fragment SpaceBannerContent on SpaceBanner {
  id
  heading
  text
  image {
    id
    gatsbyImageData
    alt
  }
}
`