import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import {
  Box,
  ButtonList,
  Container,
  Flex,
  Heading,
  Kicker,
  Section,
  Subhead,
  Text,
} from "./ui"

import { heroSection } from "./hero.css"

export default function Hero(props) {
  return (
    <Section className={heroSection}>
      <Container>
        <Flex gap={4} variant="responsive">
          <Box width="half">
            {props.image && (
              <GatsbyImage
                alt="Promotional image for End's Reach"
                image={getImage(props.image.gatsbyImageData)}
              />
            )}
          </Box>
          <Box width="half">
            <div itemscope itemType="https://schema.org/VideoGame">
              <Heading as="h1">
                {props.kicker && <Kicker>{props.kicker}</Kicker>}
                <div itemProp="name">
                  {props.h1}
                </div>
              </Heading>
              <div itemProp="genre">
                <Subhead as="h2">{props.subhead}</Subhead>
              </div>
              <div itemProp="gamePlatform availableOnDevice ">
                <Text as="p">{props.text}</Text>
              </div>
            </div>
            <ButtonList links={props.links} />
          </Box>
        </Flex>
      </Container>
    </Section>
  )
}

export const query = graphql`
  fragment HeroContent on Hero {
    id
    kicker
    h1: heading
    subhead
    text
    links {
      id
      href
      text
    }
    image {
      id
      gatsbyImageData
      alt
    }
  }
`
